import React, {useEffect} from 'react';
import '../../assets/css/style.css'
import Main from "./main";
import Header from "../header/header";
import Footer from "../footer/footer";
import $ from 'jquery';

const MainPage = () => {

    return (
        <div className='mainPage'>
            <Header/>
            <Main/>
            <Footer/>
        </div>
)
}

export default MainPage;
