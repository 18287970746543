import React from 'react';
import leftSvg from '../../assets/img/Left.svg'
import rightSvg from '../../assets/img/Right.svg'
import '../../assets/css/style.css'


const Main = () => {

    return (
        <main id="home__main">
            <div className="main__wrapper">
                <div className="col1">
                    <h2>барбершоп</h2>
                    <img src={leftSvg} alt=""/>
                </div>
                <div className="col2">
                    <h1>КОД</h1>
                </div>
                <div className="col3">
                    <h2>since 2020</h2>
                    <img src={rightSvg} alt=""/>
                </div>
            </div>
        </main>
)
}

export default Main;
