import React from 'react';
import '../../assets/css/style.css'
import Barbers from "./barbers";
import Header from "../header/header";
import Footer from "../footer/footer";


const BarbersPage = () => {

    return (
        <div className='barbersPage'>
            <Header/>
            <Barbers/>
            <Footer/>
        </div>
)
}

export default BarbersPage;
