import React, { Component, useEffect }  from 'react';
import '../../assets/css/style.css'
import {NavLink} from "react-router-dom";
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark, faPhoneVolume } from '@fortawesome/free-solid-svg-icons'



const Header = () => {
    
    useEffect(() => {
        console.log('1');
        $('.barber').hover( function () {
            let thisDescriptionP = $(this).find('.description').find('p');

            if (thisDescriptionP.hasClass('active') == 0) {
                $('.barber').find('.description').find('p').removeClass('active');
            }
            thisDescriptionP.toggleClass('active');
        });
    
        $('.menu__icon__open').click(function () {
            $(this).css('display', 'none');
            $('.menu__icon__close').css('display', 'block');
            $('.header__left__wrapper').addClass('active');
    
        });
    
        $('.menu__icon__close').click(function () {
            $(this).css('display', 'none');
            $('.menu__icon__open').css('display', 'block');
            $('.header__left__wrapper').removeClass('active');
    
        });
    });
          
    return (
        <header>
            <link rel="icon" href="../../assets/img/favicon.ico"/><meta property="og:image" content="http://barberkod.team/static/media/720_mob_bg.4cf7406e9a1111419c4a.png"/><meta property="og:locale" content="uk_UA"/><meta property="og:title" content="БАРБЕРШОП КОД"/><meta property="og:description" content="Чоловічі стрижки та гоління у Житомирі"/><meta property="og:url" content="https://barberkod.team"/><meta property="og:site_name" content="KOD"/><meta name="viewport" content="width=device-width,initial-scale=1"/>
            <div className="header__wrapper container">
                <nav className="header__left__wrapper">
                    <ul className="nav__left">
                        <li><NavLink to="/" className="nav__link">головна</NavLink></li>
                        <li><NavLink to="/barbers" className="nav__link">барбери</NavLink></li>
                        <li><NavLink to="/services" className="nav__link">послуги та ціни</NavLink></li>
                        <li><NavLink to="/works" className="nav__link">наші роботи</NavLink></li>
                        <li><a href="http://kodbaza.com" className="nav__link">академія</a></li>
                    </ul>
                </nav>

                <nav className="header__right__wrapper">
                    <ul className="nav__right">
                        <li><a className="nav__link work__time" href="">10:00 до 21:00</a></li>
                        <li><a className="nav__link header__phone" href="tel:+380966996182">+38 (096) 699 6182</a></li>
                        <li>
                            <a className="nav__link header__button" href="https://easyweek.com.ua/k-o-d">
                                Записатись<i className="fa-regular fa-circle"></i>
                            </a>
                        </li>
                        <li>
                            <a className="nav__link header__phone__icon" href="tel:+380966996182">
                                <FontAwesomeIcon icon={faPhoneVolume} />
                                </a>
                        </li>
                    </ul>
                </nav>
                
                <FontAwesomeIcon icon={faBars} className="menu__icon__open" />
                <FontAwesomeIcon icon={faXmark} className="menu__icon__close" />
                
            </div>
        </header>
)
}

export default Header;
