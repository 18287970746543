import React from 'react';
import '../../assets/css/style.css'
import Works from "./works";
import Header from "../header/header";
import Footer from "../footer/footer";


const WorksPage = () => {

    return (
        <div className='worksPage'>
            <Header/>
            <Works/>
            <Footer/>
        </div>
)
}

export default WorksPage;
