import './App.css';
import './assets/css/style.css'
import Header from "./components/header/header";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Footer from "./components/footer/footer";
import MainPage from "./components/main/mainPage";
import BarbersPage from "./components/barbers/barbersPage";
import ServicesPage from "./components/services/servicesPage";
import WorksPage from "./components/works/worksPage";
import React, { Component }  from 'react';

function App() {
    return (
        <BrowserRouter>
            <div className="appWrapper">
                {/*<Header/>*/}
                <div className='app-wrapper-content'>
                    <Routes>
                        <Route path='/*'
                               element={<MainPage/>}/>
                        <Route path='/barbers/*'
                               element={<BarbersPage/>}/>
                        <Route path='/services/*'
                               element={<ServicesPage/>}/>
                        <Route path='/works/*'
                               element={<WorksPage/>}/>

                    </Routes>
                </div>
                {/*<Footer/>*/}
            </div>
        </BrowserRouter>
    );
}

export default App;
