import React from 'react';
import '../../assets/css/style.css'


import image7 from '../.././assets/img/7.png'
import image8 from '../.././assets/img/8.png'
import image9 from '../.././assets/img/9.png'
import image16 from '../.././assets/img/16.png'
import image17 from '../.././assets/img/17.png'
import image18 from '../.././assets/img/18.png'
import image19 from '../.././assets/img/19.png'
import image20 from '../.././assets/img/20.png'
import image21 from '../.././assets/img/21.png'
import image1 from '../.././assets/img/1.png'
import image2 from '../.././assets/img/2.png'
import image3 from '../.././assets/img/3.png'
import image4 from '../.././assets/img/4.png'
import image5 from '../.././assets/img/5.png'
import image6 from '../.././assets/img/6.png'
import image10 from '../.././assets/img/10.png'
import image11 from '../.././assets/img/11.png'
import image12 from '../.././assets/img/12.png'
import image13 from '../.././assets/img/13.png'
import image14 from '../.././assets/img/14.png'
import image15 from '../.././assets/img/15.png'
import image22 from '../.././assets/img/22.png'
import image23 from '../.././assets/img/23.png'
import image24 from '../.././assets/img/24.png'
import image25 from '../.././assets/img/25.png'



const Works = () => {

    return (
        <main>
            <div className="works__wrapper">
                <div className="work">
                    <img src={image7} alt=""/>
                </div>

                <div className="work">
                    <img src={image8} alt=""/>
                </div>

                <div className="work">
                    <img src={image9} alt=""/>

                </div>
                <div className="work">
                    <img src={image16} alt=""/>
                </div>

                <div className="work">
                    <img src={image17} alt=""/>
                </div>
                <div className="work">
                    <img src={image18} alt=""/>
                </div>
                <div className="work">
                    <img src={image19} alt=""/>
                </div>
                <div className="work">
                    <img src={image20} alt=""/>
                </div>
                <div className="work">
                    <img src={image21} alt=""/>
                </div>
                <div className="work">
                    <img src={image1} alt=""/>
                </div>


                <div className="work">
                    <img src={image2} alt=""/>
                </div>

                <div className="work">
                    <img src={image3} alt=""/>
                </div>

                <div className="work">
                    <img src={image4} alt=""/>
                </div>

                <div className="work">
                    <img src={image5} alt=""/>
                </div>

                <div className="work">
                    <img src={image6} alt=""/>
                </div>


                <div className="work">
                    <img src={image10} alt=""/>
                </div>


                <div className="work">
                    <img src={image11} alt=""/>
                </div>


                <div className="work">
                    <img src={image12} alt=""/>
                </div>


                <div className="work">
                    <img src={image13} alt=""/>
                </div>
                <div className="work">
                    <img src={image14} alt=""/>
                </div>


                <div className="work">
                    <img src={image15} alt=""/>
                </div>


                <div className="work">
                    <img src={image22} alt=""/>
                </div>
                <div className="work">
                    <img src={image23} alt=""/>
                </div>
                <div className="work">
                    <img src={image24} alt=""/>
                </div>
                <div className="work">
                    <img src={image25} alt=""/>
                </div>
            </div>

        </main>
)
}

export default Works;
