import React from 'react';
import '../../assets/css/style.css'


const Services = () => {

    return (
        <main className="main__services">
            <div className="service_wrapper container">
                <div className="service">
                    <span className="service__title">Стрижка</span>
                    <span className="service__price">400 - 900 грн.</span>
                    <p className="service__description">Ми цінуємо кофорт відвідувачів. Наші майстри індивідуально
                        підбирають форму стрижки під форму голови та зовнішній стиль клієнта для того , щоб підкреслити
                        індивідуальність та щоб після послуги було комфортно доглядати за волоссям.</p>
                    <hr/>
                </div>
                <div className="service">
                    <span className="service__title">Стрижка бороди</span>
                    <span className="service__price">350 - 700 грн.</span>
                    <p className="service__description">Популярна послуга. В яку входить стрижка, контуринг та груминг,
                        розпарювання гарячим рушником</p>
                    <hr/>

                </div>
                <div className="service">
                    <span className="service__title">Коротка стрижка під насадку</span>
                    <span className="service__price">300 - 600 грн.</span>
                    <p className="service__description">Стрижка яка виключає роботу ножиць, може в себе включати складні
                        елементи «Fade, SkinFade, Taper Fade...»</p>
                    <hr/>

                </div>
                <div className="service">
                    <span className="service__title">Дитяча стрижка</span>
                    <span className="service__price">350 - 700 грн.</span>
                    <p className="service__description">Дітей ми також обслуговуєм починаючи з п‘яти років.</p>
                    <hr/>

                </div>
                <div className="service">
                    <span className="service__title">Укладання волосся</span>
                    <span className="service__price">150 грн.</span>
                    <p className="service__description">Допоможе вашій зачісці виглядати на всі 100%. В послугу
                        укладання входить: миття голови, укладання феном та укладання косметичним засобом «паста, глина,
                        пудра, крем».</p>
                    <hr/>

                </div>
                <div className="service">
                    <span className="service__title">Камуфляж сивини</span>
                    <span className="service__price">450 - 500 грн.</span>
                    <p className="service__description">Камуфлювання волосся - це ідеальне рішення для маскування
                        сивини. Воно надає волоссю насиченого кольору та природнього блиску.</p>
                    <hr/>

                </div>
                <div className="service">
                    <span className="service__title">Пілінг</span>
                    <span className="service__price">150 грн.</span>
                    <p className="service__description">Cупер-послуга для людей які відчувають дискомфорт від лупи та
                        випадіння волосся. Проходить в дві фази: 1. Очищення, наносимо скраб-маску, масажними рухами
                        розподіляємо по голові та волоссі, після змиваємо скраб за допомогою шампуню глибокої чистки
                        проти
                        лупи. 2. Відновлення, змиваємо шампунь та одразу миємо голову за допомогою шампуню проти
                        випадіння волосся, так само змиваємо, ретельно витираємо голову рушником та наносимо спрей проти
                        випадіння волосся. Після послуги у вас буде максимально чиста голова та волосся та ви будете
                        відчувати приємний освіжаючий ефект.</p>
                    <hr/>

                </div>
                <div className="service">
                    <span className="service__title">Догляд за обличчям від London Grooming</span>
                    <span className="service__price">600 грн.</span>
                    <p className="service__description">5 кроків до ідеальної шкіри:<br/>1. Очищення шкіри спеціальним
                        гелем (алоє вера, евкаліпт, екстракт гранату)<br/>2. Вугільна маска, яка допоможе очистити пори,
                        зменшить висипання (вугілля, екстракт кавових зерен, мароканська червона глина)<br/>3.
                        Відновлюючий гель для шкіри під очима (гіалуронова кислота, алоє вера)<br/>4. Антивікова
                        сиворотка (олія шипшини, масло жожоба)<br/>5. Зволожуючий крем для обличчя (екстракт морських
                        водоростей, олія жожоба, бджолиний віск)</p>
                    <hr/>

                </div>
                <div className="service">
                    <span className="service__title">Гоління</span>
                    <span className="service__price">400 - 450 грн.</span>
                    <p className="service__description"></p>
                    <hr/>
                </div>
            </div>
        </main>
)
}

export default Services;
