import React from 'react';
import '../../assets/css/style.css'

import dpetrun from '../../assets/img/dpetrun.jpg'
import barber1 from '../../assets/img/barber1.png'
import sasha from '../../assets/img/sasha.jpg'
import bbivan from '../../assets/img/bb-ivan.jpg'
import bbkarina from '../../assets/img/bb-karina.jpg'
import bbmihajlo from '../../assets/img/bb-mihajlo.jpg'
import bboleg from '../../assets/img/bb-oleg.jpg'
import bboleksij from '../../assets/img/bb-oleksij.jpg'
import barber5 from '../../assets/img/barber5.png'
import vladm from '../../assets/img/bb-vladislav.jpg'
import lyba3 from '../../assets/img/lyba3.jpg'
import kianuz from '../../assets/img/kianuz.jpg'
import roman_s from '../../assets/img/roman_s.jpg'
import anna from '../../assets/img/anna.jpg'
import nat from '../../assets/img/nat.jpg'
import inna from '../../assets/img/inna.jpg'
import evgen from '../../assets/img/evgen.jpg'
import bbanastasiya from '../../assets/img/bb-anastatiya.jpg'

const Barbers = () => {

    return (
        <main>
            <div className="barbers__wrapper">

                <div className="barber">
                    <div className="photo">
                        <img src={dpetrun} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Дмитро Петруняк</span>
                        <p className="active">Душа барбершопу, полюбляє щоб все було максимально по канонам. Провідні машинки, леза, олдскульна класика. В цілому співрозмовник та файний майстер.</p>
                    </div>
                </div>


                <div className="barber">
                    <div className="photo">
                        <img src={barber1} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Дмитро Костерін</span>
                        <p className="active">Віртуоз чоловічих зачісок, духовний та професійний наставник усієї команди КОД. Бренд амбасадор італійського бренду Xflex. Досвід роботи з 2013 року.</p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={sasha} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Олександр Курінний</span>
                        <p className="active">Про Сашу можна сказати що він постійно в тонусі, кожен день прагне бути кращим, завжди шукає нові джерела натхнення, дуже тонко відчуває людей та може підтримати будь яку розмову, завжди на позитиві і при гарному настрої.</p>
                    </div>
                </div>
                
                <div className="barber">
                    <div className="photo">
                        <img src={barber5} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Ілля Мазяр</span>
                        <p className="active">Впевнений та досвідчений барбер. Щодо зачісок полюбляє сучасну класику. Слухає Phonk, полюбляє японський живопис, обожнює кіно-вселенну Marvel, серію Ігор Assassin’s Creed, та найбільший серед нас футбольний фанат, так що, якщо вам потрібно з кимось поговорити про футбол та отримати круту зачіску, вам до Іллі.</p>
                    </div>
                </div>


                <div className="barber">
                    <div className="photo">
                        <img src={vladm} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Владислав Мельничук</span>
                        <p className="active">Досить досвідченний барбер! Полюбляє стригти зачіски з елементом Fade, слухає зарубіжний Хіп-Хоп, полюбляє серіал «Чорне зеркало» та займається танцями Брейк-данс, Хіп-Хоп, Бачата.</p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={lyba3} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Любов Зайнчковська</span>
                        <p className="active">В перукарській сфері більше 3-х років, складається враження що Люба знайде підхід абсолютно до будь-якої людини. Дуже спокійна натура, полюбляє книжки Ситвена Кінга, фільм «Маска» та «Скуби-Ду».</p>
                    </div>
                </div>



                <div className="barber">
                    <div className="photo">
                        <img src={kianuz} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Євген Кіяну</span>
                        <p className="active">Наш молодший, дуже старанно та відповідально підходить до роботи, дає перевагу американському стилю як в стрижках так і в музиці, максимально урівноважений та ввічливий.</p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={anna} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Анна Симончук</span>
                        <p className="active"></p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={nat} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Наталія Титарчук</span>
                        <p className="active"></p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={inna} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Інна Василенко</span>
                        <p className="active"></p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={evgen} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Євген Нікітчук</span>
                        <p className="active"></p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={bbivan} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Іван Масіч</span>
                        <p className="active"></p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={bbkarina} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Каріна</span>
                        <p className="active"></p>
                    </div>
                </div>
                
                <div className="barber">
                    <div className="photo">
                        <img src={bbmihajlo} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Михайло Брановицький</span>
                        <p className="active"></p>
                    </div>
                </div>
                                
                <div className="barber">
                    <div className="photo">
                        <img src={bboleg} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Олег Докукін</span>
                        <p className="active"></p>
                    </div>
                </div>
                                
                <div className="barber">
                    <div className="photo">
                        <img src={bboleksij} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Олексій Муляр</span>
                        <p className="active"></p>
                    </div>
                </div>

                <div className="barber">
                    <div className="photo">
                        <img src={bbanastasiya} alt=""/>
                    </div>
                    <div className="description">
                        <span className="name">Анастасія Крошко</span>
                        <p className="active"></p>
                    </div>
                </div>

            </div>
        </main>
)
}

export default Barbers;
