import React from 'react';
import '../../assets/css/style.css'
import Services from "./services";
import Header from "../header/header";
import Footer from "../footer/footer";


const ServicesPage = () => {

    return (
        <div className='servicesPage'>
            <Header/>
            <Services/>
            <Footer/>
        </div>
)
}

export default ServicesPage;
