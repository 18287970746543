import React from 'react';
import '../../assets/css/style.css'
import instaSvg from '../../assets/img/Insta.svg'
import YouSvg from '../../assets/img/You.svg'
import partner1 from '../../assets/img/partner1.png'
import locationSvg from '../../assets/img/location.svg'
import phone_2 from '../../assets/img/phone2.svg'

const Footer = () => {

    return (
        <footer>
            <div className="footer_wrapper container">
                <ul>
                    
                    <ul className='addresses'>
                        <li className="footer__address">
                        <a href="tel:+380736996182"><img width="40" src={phone_2} alt=""/></a><a href="https://maps.app.goo.gl/KscCMmbvLewHmozh6">Житомир, Майдан Соборний, 2/2</a> 
                        </li>
                        <li className="footer__address">
                        <a href="tel:+380636996189"><img width="40" src={phone_2} alt=""/></a><a href="https://maps.app.goo.gl/UhPJVLV1zKxEHnBP6">Житомир, вул. Київська, 29</a> 
                        </li>
                        <li className="footer__address">
                        <a href="tel:+380966996182"><img width="40" src={phone_2} alt=""/></a><a href="https://g.page/barber0412?share">Житомир, вул. Львівська 1</a> 
                        </li>
                        <li className="footer__address">
                        <a href="tel:+380686996188"><img width="40" src={phone_2} alt=""/></a><a href="https://maps.app.goo.gl/QFYrsPsiaNWDC7e66">Житомир, вул. Київська 104</a> 
                        </li>
                    </ul>

                    <ul className="footer__partner">
                        <a href="https://forbarber.com">
                            <img width="120" src={partner1} alt=""/>
                        </a>
                    </ul>
                    
                    <li className="footer__instagram">
                        <a href="https://www.instagram.com/kod.barbershop/">
                            <img width="30" src={instaSvg} alt=""/>
                        </a>
                    </li>
                    
                    <li className="footer__youtube">
                        <a href="https://youtube.com/@kodbaza1/videos"><img width="30" src={YouSvg} alt=""/></a>
                    </li>
{/* 
                    <li>
                        <a href="tel:+380966996182"><img width="30" src={phone_2} alt=""/></a>
                    </li> */}


                </ul>
            </div>
        </footer>
)
}

export default Footer;
